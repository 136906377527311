<style>
.subsection {
  font-size: 0.8em;
  color: #999;
}
</style>

<template>
  <modal name="INDICE_BUSCA" height="auto" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="form">
        <div class="xrow">
          <div class="col">
            <label>{{parametro ? parametro.desc : ''}}</label>
          </div>
        </div>
        <hr>
        <div class="xrow">
          <div class="col">
            <div class="form-group">
              <label>Linguagem do índice</label>
              <small class="subsection">Utilizado para remover preposições</small>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="linguagem" id="linguagem">
                <option v-for="(chave, valor) in linguagens" :value="valor">{{ chave }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="xrow">
          <div class="col">
            <div class="form-group">
              <label>Peso dos campos</label>
              <small class="subsection">Quanto maior o peso, maior a relevância</small>
            </div>

            <div class="form-group row" v-for="(indice) in indices">
              <div class="col-2-3">
                <input class="form-control" type="text" v-model="indice.campo">
              </div>
              <div class="col-1-3">
                <input class="form-control text-right" type="number" v-model="indice.peso">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': false }" v-on:click="updateparametro(parametro)">
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import clone from "just-clone";
import _map from "lodash/map";
import CloseModal from "../../../components/CloseModal";

export default {
  name: "INDICE_BUSCA",
  props: ["update", "close", "parametro"],
  components: {
    CloseModal,
  },
  data() {
    return {
      indices: "",
      linguagem: "portuguese",
      linguagens: {
        none: "Nenhuma",
        portuguese: "Português",
      },
    };
  },
  methods: {
    updateparametro() {
      let parametro = clone(this.parametro);
      parametro.valor = {
        indices: this.parseIndexesToSave(this.indices),
        linguagem: this.linguagem,
      };
      this.update(parametro);
    },
    parseIndexesToEdit(indexes) {
      let idx = [];

      _map(indexes, (peso, campo) => {
        idx.push({
          campo,
          peso,
        });
      });

      return idx;
    },
    parseIndexesToSave(indexes) {
      let idx = {};

      _map(indexes, (indice) => {
        idx[indice.campo] = indice.peso;
      });

      return idx;
    },
  },
  mounted() {
    this.linguagem = this.parametro.valor
      ? this.parametro.valor.linguagem
      : "portuguese";
    this.indices = this.parametro.valor
      ? this.parseIndexesToEdit(this.parametro.valor.indices)
      : [];
  },
};
</script>
